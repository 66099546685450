import React from "react";
import SEO from "../components/seo";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import Header from "../components/header";
import PortfolioIndex from "../components/portfolioIndex";
import {  
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";

const Portfolio = () => {
      // flooring: relativeDirectory: { eq: "flooring" } },
      // other:  relativeDirectory: { eq: "other" } },
  return (
  <Layout>
    <SEO title="Portfolio" path='portfolio'/>
    <Header />
    
    <main id='main'>
      <div className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <PortfolioIndex />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
    </main>

    <ContactForm/> 
    
  </Layout>
)};




export default Portfolio;
